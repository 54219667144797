import React, {useEffect, useRef} from "react"
import Single from "../../single"
import info1 from "../../../../static/img/irstrat/video-difusion.png";
import noticias11 from "../../../../static/img/blog/noticias-11-.png";

const Detail = (props) => {
    const isMountedComponent = useRef(true)
    useEffect(() => {
        if (isMountedComponent.current) {
            window.jquery_scripts()
        }
        return () => {
            isMountedComponent.current = false
        }
    })

    return (
        <Single location={props.location} title='Restauración de la confianza luego de una mala racha'>
            <div className="main_blog_items">
                <div className="main_blog_item">
                    <div className="main_blog_image img_hover">
                        <img alt={" "} src={noticias11}/>
                    </div>
                    <div className="main_blog_text">
                        <div className="post_info">
                            <a href="#" className="date">
                                04 Dic. 2020
                            </a>
                            <div className="blog_author_area">
                 <span>
                  Por : Miguel Bermejo
                </span>
                                <span>
                    Comunicación
                </span>
                            </div>
                        </div>

                        <h2>Restauración de la confianza luego de una mala racha</h2>
                        <br/>
                    </div>
                </div>
                <div className="s_main_text">
                        <p>La crisis sanitaria por la pandemia de COVID-19 ha traído consigo desafíos importantes para la
                        economía mundial, afectando a empresas, gobiernos, inversionistas y la población en general. En este
                        sentido, el mundo financiero está enfrentando una realidad para la cual no se encontraba preparado.
                        Tras finalizar dos trimestres consecutivos en convivencia con el virus SARS-CoV-2, es importante
                        comprender el sentir de los inversionistas ante la nueva información financiera revelada, en especial
                        si no es favorable, y preparar estrategias que ayuden a restaurar el nivel de confianza que los diversos
                        grupos de interés tienen en la compañía.  </p>

                        <p>A pesar de que las empresas prefieran que todos los trimestres se reporten cifras que se encuentren
                        por encima de las expectativas, no siempre es así. La realidad es que las finanzas trimestrales fluctúan
                        por muchas razones, por lo que, cuando se acumulan varios trimestres sin cumplir las proyecciones
                        de la dirección o estimaciones externas, surgen desafíos más importantes en materia de
                        comunicación y credibilidad.  </p>

                        <p>En la mayoría de los casos, la comunidad inversora y analista apoyará a las compañías que cuenten
                        con un equipo directivo considerado como “digno de confianza” y que proyecte la capacidad de hacer
                        crecer con éxito un negocio a largo plazo. Debido a que la confianza fomenta el crecimiento
                        económico, es importante construirla a través de la transparencia y puntualidad, especialmente al
                        momento de preparar los informes financieros, el material de las conferencias telefónicas y otros
                        documentos de comunicación significativos.</p>

                        <p>La confianza y reputación que transmite una compañía es fundamental para los inversionistas y
                        analistas, donde uno de los efectos positivos de este hecho es que los consumidores perciben que el
                        peligro de una crisis es menor cuando se trata de una empresa de gran reputación. Por todo lo
                        anterior, surge un marco de cuatro grandes pasos que una compañía puede llevar a cabo para
                        fortalecerse en materia de relación con inversionistas mientras navega por una situación
                        desfavorable:</p>

                        <p><em><b>• Estar en control de la situación.</b></em> Cuando una empresa atraviesa una mala racha, se genera
                        una presión incremental sobre la comunicación con los inversores, más aún, cuando se
                        enfrenta a situaciones fuera de su dominio (ya sea por condiciones macroeconómicas o por
                          alguna externalidad). Por esto, es necesario articular un plan a la brevedad para resolver
                          estos problemas eficientemente y no comprometer la confianza de los inversionistas. Estar
                          en control se traduce en tres cosas: reconocer la situación, asegurarse que se aborde la
                          problemática y asumir la responsabilidad sobre la ejecución de la estrategia. </p>

                        <p><em><b>• Abordar la crisis abiertamente y confiar en los inversores y clientes.</b></em> En tiempos de crisis, la
                        comunicación es clave, por lo que el primer paso es realizar un honesto recuento del estado
                        actual de la compañía. Para esto, la empresa podría contratar a un consultor externo y
                        comunicar que se está llevando a cabo una revisión formal y profunda para determinar el
                        mejor camino a seguir.  </p>

                        <p>Mediante la comunicación del plan de acción se logran dos puntos muy importantes: 1.- Se
                        establece el hecho de que para la compañía no bastará con “seguir como hasta ahora”; y 2.- Se crea la expectativa de que los problemas serán rectificados y controlados. De esta
                        manera se tranquiliza tanto al mercado como a los colaboradores, pues se transmite que la
                        dirección es honesta y está dispuesta a evaluarse de manera crítica por el bien de la empresa
                        y de sus grupos de interés.</p>

                        <p><em><b>• Investigar la causa de la crisis.</b></em> Una vez abordada la problemática de manera directa, es
                        tiempo de investigar el origen de la crisis para asegurarse de que no vuelva a suceder.
                        Dependiendo de la gravedad de la situación, el consejo deberá trazar el curso de acción, el
                        cual podría implicar cambios en el personal o en la forma de trabajar.  </p>

                        <p>Es muy importante informar de manera puntual y a detalle sobre los avances que la compañía
                        ha logrado en torno a la investigación, así como explicar qué se está haciendo y por qué. Esto
                        permite a la comunidad inversionista supervisar los progresos en relación con los objetivos
                        declarados, lo que con el tiempo conducirá a una recuperación de la confianza.</p>

                        <p><em><b>• Comprometerse con el cambio.</b></em>  El último obstáculo para reestablecer la confianza después
                        de una situación desfavorable es el compromiso con el cambio: las compañías deben abordar
                        el problema y asegurarse de que no pueda volver a ocurrir; o en caso de que esto no sea
                        posible (como es el caso de una pandemia), tener definido un plan de respaldo cuya
                        ejecución inicie de manera inmediata.   </p>

                        <p>En cualquier caso, el establecer expectativas realistas y de manera adecuada contribuirá
                        significativamente en la forma en que se juzga a la empresa. Asimismo, proporcionar metas
                        mensurables, tanto cualitativas como cuantitativas resultará beneficioso para las partes
                        involucradas, ya que permite que los inversores y analistas construyan sus propias
                        expectativas y modelos. Una autoevaluación exhaustiva, seguida de una comunicación clara
                        y una ejecución exitosa, brindará a los inversores la tranquilidad de que la compañía se
                        encuentra en el camino correcto.</p>

                        <p>Es indiscutible que la crisis financiera ha perjudicado a prácticamente todas las empresas, sin
                        embargo, considerando los cuatro pasos descritos anteriormente, es posible reconstruir la confianza
                        de los inversores y analistas. En tiempos de crisis, la clave es asumir una comunicación clara y eficiente
                        entre las compañías y sus accionistas, lo que posibilita la declaración de medidas correctivas y permite
                        a las partes interesadas supervisar la ejecución futura. </p>

                        <p>A medida que se avance con éxito en el camino trazado, las relaciones con los inversores mostrarán
                        señales de recuperación, propiciando un aumento en su lealtad, así como en su compromiso y
                        cooperación con la compañía. Por su parte, es sumamente importante que los equipos de RI asuman
                        la crisis como una oportunidad de fortalecimiento.</p>

                        <p>Referencias Bibliográficas
                          <ul>
                            <li>Hellman, J. (2020). How to restore trust after a run of underperformance. Noviembre 02, 2020.  <a href="https://www.irmagazine.com/small-cap/how-restore-trust-after-run-underperformance" target="_blank">IR Magazine</a>.</li>
                            <li>PWC. (2015). Rebuilding trust after times of crisis: A practical guide [<a href="https://www.pwc.nl/nl/assets/documents/pwc-rebuilding-trust.pdf" target="_blank">White Paper</a>]. Febrero 2015.</li>
                          </ul>
                        </p>

                </div>
              </div>
        </Single>
    )
}

export default Detail
